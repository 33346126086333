<template>
  <div id="success">
    <p>Done! Your Aira Access location has been successfully submitted. We will update you via email in the next 24-48 business hours once the geofence has been configured.</p>

    <p>
      <router-link :to="{ name: 'AccessPartner' }">
        Submit another Aira Access Location
      </router-link>
    </p>

    <p><a href="https://aira.io">Return Home</a></p>
  </div>
</template>

<script>
export default {
  name: 'AccessPartnerSuccess',
};
</script>

<style lang="scss">
// @import '../../styles/theme/_mobile.scss';

#success {
  width: 50%;
  margin: auto;
  margin-top: 50px;
  font-size: larger;
}
</style>
